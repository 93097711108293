import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { IoIosArrowBack } from 'react-icons/io';
import Lottie from 'react-lottie';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Background, Content } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo.svg';
import sendMail from '~/assets/animations/send_mail.json';

interface IFormData {
  email: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Informe um e-mail válido')
          .required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('admins/sessions/forgot-password', {
        email: data.email,
      });

      setEmailSent(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        setEmailSent(true);
      }
    }
  }, []);

  return (
    <Container className="container container-lg-fluid">
      <div className="row">
        <Content className="col-lg-7 col-xl-6 col-xxl-5 d-flex flex-column justify-content-center align-items-center">
          {!emailSent ? (
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="px-3 px-sm-4 px-md-5 px-lg-0"
            >
              <div className="mb-5">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="bg-transparent border-0 mb-3 d-block"
                >
                  <IoIosArrowBack size={24} color="#fff" />
                </Link>

                <h1 className="h2 fw-semibold mb-1">Esqueceu a senha?</h1>
                <p className="text-gray">
                  Não se preocupe, insira seu e-mail para restaurar uma nova
                  senha
                </p>
              </div>
              <div className="mb-5 py-5">
                <label htmlFor="email" className="mb-2 fw-medium">
                  E-mail
                </label>
                <Input
                  type="email"
                  placeholder="email@example.com"
                  name="email"
                />
              </div>
              <div className="mb-5">
                <button
                  type="submit"
                  className="btn btn-primary mb-5 w-100 fw-semibold"
                >
                  Enviar
                </button>
              </div>
            </Form>
          ) : (
            <div className="px-3 px-sm-4 px-md-5 px-lg-0">
              <div className="mb-4 pb-5">
                <h2 className="fw-semibold mb-1 text-center">
                  Email enviado com sucesso
                </h2>
                <p className="text-gray text-center">
                  Verifique sua caixa de entrada, spam ou lixo eletrônico
                </p>
              </div>
              <Lottie
                options={{
                  animationData: sendMail,
                  autoplay: true,
                  loop: false,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={160}
                width={160}
              />
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="btn btn-primary mt-4 w-100 fw-semibold"
              >
                Voltar para Login
              </Link>
            </div>
          )}
        </Content>
        <Background className="d-none d-lg-flex flex-column justify-content-end align-items-end px-0 pt-4">
          <div className="px-5 pb-5">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Background>
      </div>
    </Container>
  );
};

export default Login;
