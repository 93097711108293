import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { formatPrice } from '~/utils/format';

import { Container } from './styles';

export interface ISerie {
  name: string;
  data: number[];
}

interface IGraphProps {
  series: ISerie[];
  categories: string[];
  title?: string;
  className?: string;
  width?: number;
  height?: number;
}

const Graph: React.FC<IGraphProps> = ({
  series,
  categories,
  title,
  className,
  width,
  height,
}) => {
  const options = useMemo<ApexOptions>(() => {
    if (window.innerWidth >= 992) {
      return {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
          selection: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories,
          labels: {
            style: {
              colors: '#707070',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (data) => formatPrice(data),
            style: {
              colors: '#707070',
            },
          },
        },
        tooltip: {
          y: {
            formatter: (value) => formatPrice(value),
          },
          theme: 'dark',
        },
        colors: ['rgba(229, 9, 20, 1)', 'rgba(32, 32, 32, 1)'],
        markers: {
          strokeColors: '#4E4F51',
        },
        fill: {
          colors: [
            'rgba(229, 9, 20, 1)',
            'rgba(187, 187, 187, 1)',
            'rgba(58, 58, 58, 1)',
            'rgba(0, 157, 101, 1)',
          ],
          type: 'gradient',
          gradient: {
            gradientToColors: [
              ['rgba(229, 9, 20, 1)', 'rgba(229, 9, 20, 0.5)'],
              ['rgba(32, 32, 32, 1)', 'rgba(32, 32, 32, 0.5)'],
            ] as unknown as string[],
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
        grid: {
          show: false,
        },
        legend: {
          showForSingleSeries: true,
        },
      };
    }

    let tickAmount = 2;
    if (window.innerWidth >= 768) {
      tickAmount = 5;
    } else if (window.innerWidth >= 576) {
      tickAmount = 4;
    }

    return {
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories,
        labels: {
          formatter: (data) => formatPrice(parseFloat(data)),
          style: {
            colors: '#707070',
          },
        },
        tickAmount,
        min: 0,
      },
      yaxis: {
        labels: {
          style: {
            colors: '#707070',
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => formatPrice(value),
        },
        theme: 'dark',
      },
      colors: ['rgba(229, 9, 20, 1)', 'rgba(32, 32, 32, 1)'],
      markers: {
        strokeColors: '#4E4F51',
      },
      legend: {
        showForSingleSeries: true,
      },
    };
  }, [categories]);

  return (
    <Container className={className}>
      {title && (
        <div className="d-sm-flex align-items-center justify-content-between title-chart">
          <div>
            <h2 className="h3 mb-3 font-weight-400">{title}</h2>
          </div>
        </div>
      )}
      <ReactApexChart
        type={window.innerWidth >= 992 ? 'area' : 'bar'}
        options={options}
        series={series}
        width={width}
        height={height}
      />
    </Container>
  );
};

export default Graph;
