import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
import { useField } from '@unform/core';
import { IoIosArrowDown } from 'react-icons/io';

import { CgClose } from 'react-icons/cg';
import { Container, Option } from './styles';

export interface IOption {
  id?: number | string | undefined;
  value: string;
  selected: boolean;
  notSelectable?: boolean;
}

interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  height?: string;
  options: IOption[];
  optionsSelected?: IOption[];
  readOnly?: boolean;
  onChangeText?(value: string): void;
  onRemove?(value: IOption): void;
  hasError?(hasError: boolean): void;
  multiSelect?: boolean;
}

const Select: FC<SelectProps> = ({
  name,
  height,
  hasError,
  className,
  options,
  optionsSelected: optionsSelectedData,
  onFocus,
  onBlur,
  readOnly,
  onChange,
  onChangeText,
  multiSelect,
  onRemove,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputFocusRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<IOption[]>(options);
  const [optionsSelected, setOptionsSelected] = useState<IOption[]>([]);
  const [optionSelected, setOptionSelected] = useState<
    number | string | undefined
  >(undefined);
  const [valueSelected, setValueSelected] = useState('');
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    if (optionsSelectedData) {
      setOptionsSelected(optionsSelectedData);
    }
  }, [optionsSelectedData]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue() {
        const valueFound = values.find((value) => value.id);
        if (valueFound) {
          const newValue = values.map((value) => {
            if (value.id === valueFound.id) {
              return {
                ...value,
                selected: true,
              };
            }

            return {
              ...value,
              selected: false,
            };
          });

          setValues(newValue);
          setOptionSelected(valueFound.id);
          setValueSelected(valueFound.value);
        } else {
          setOptionSelected(undefined);
          setValueSelected('');
        }
      },
    });
  }, [defaultValue, error, fieldName, registerField, values]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
    setValues(options);
  }, [error, hasError, options]);

  useEffect(() => {
    const selectedOption = values.find((option) => option.selected);
    if (selectedOption) {
      if (selectedOption.value === 'Selecione') {
        if (!isFocuses) {
          setOptionSelected(selectedOption.id);
          setValueSelected(selectedOption.value);
        }
      } else {
        setOptionSelected(selectedOption.id);
        setValueSelected(selectedOption.value);
      }
    }
  }, [defaultValue, isFocuses, values]);

  useEffect(() => {
    if (optionsSelected.length > 0) {
      setValueSelected('');
    }
  }, [optionsSelected.length]);

  const handleInputFocus = useCallback(
    (e) => {
      const { value } = e.target;
      if (value === 'Selecione') {
        setValueSelected('');
      }
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true && !readOnly);
    },
    [onFocus, readOnly]
  );

  const handleInputBlur = useCallback(
    (e) => {
      const { value } = e.target;
      if (!value && optionsSelected.length === 0) {
        setValueSelected('Selecione');
      }
      if (onBlur) {
        onBlur(e);
      }
      setTimeout(() => {
        setIsFocuses(false);
      }, 300);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur, optionsSelected.length]
  );

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (onChangeText) {
        onChangeText(value);
      }
      setValueSelected(value);
    },
    [onChangeText]
  );

  const handleClick = useCallback(
    (option: IOption) => {
      if (!multiSelect) {
        setOptionSelected(option.id);
        setValueSelected(option.value);

        const newValues: IOption[] = values.map((optionData) => {
          if (optionData.id === option.id) {
            return {
              id: optionData.id,
              value: optionData.value,
              selected: true,
              notSelectable: optionData.notSelectable,
            };
          }

          return {
            id: optionData.id,
            value: optionData.value,
            selected: false,
            notSelectable: optionData.notSelectable,
          };
        });

        setValues(newValues);

        if (onChange) {
          onChange(option as unknown as ChangeEvent<HTMLInputElement>);
        }
      } else {
        const newOptions = values.filter(
          (optionData) => optionData.id !== option.id
        );
        const newOptionsSelected = optionsSelected.slice();
        newOptionsSelected.push({
          ...option,
          selected: true,
        });
        setOptionsSelected(newOptionsSelected);
        setValues(newOptions);

        if (onChange) {
          onChange(
            newOptionsSelected as unknown as ChangeEvent<HTMLInputElement>
          );
        }

        setTimeout(() => {
          if (inputFocusRef.current) {
            if (newOptions.length > 0) {
              inputFocusRef.current.focus();
              setIsFocuses(true);
            }
          }
        }, 300);
      }
    },
    [multiSelect, onChange, optionsSelected, values]
  );

  const handleRemoveOptionSelected = useCallback(
    (option: IOption) => {
      const newOptionsSelected = optionsSelected.filter(
        (optionData) => optionData.id !== option.id
      );

      setOptionsSelected(newOptionsSelected);
      setValues((state) => [...state, { ...option, selected: false }]);

      if (newOptionsSelected.length === 0) {
        setValueSelected('Selecione');
      }

      if (onChange) {
        onChange(
          newOptionsSelected as unknown as ChangeEvent<HTMLInputElement>
        );
      }

      if (onRemove) {
        onRemove(option);
      }
    },
    [onChange, onRemove, optionsSelected]
  );

  const handleClickArrow = useCallback(() => {
    if (inputFocusRef.current) {
      inputFocusRef.current.focus();
    }
  }, []);

  return (
    <>
      <Container
        className={`${className} position-relative`}
        height={height}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div className="d-flex align-items-center">
          <div className="w-100 d-flex align-items-center flex-wrap">
            {multiSelect && optionsSelected.length > 0 && (
              <>
                {optionsSelected.map((option) => (
                  <Option
                    key={option.id}
                    className="bg-primary rounded-pill px-4 py-1 m-1 d-flex align-items-center"
                  >
                    <span>{option.value}</span>
                    <button
                      type="button"
                      className="bg-transparent border-0"
                      onMouseUp={() => handleRemoveOptionSelected(option)}
                    >
                      <CgClose size={17} color="#fff" className="ms-3" />
                    </button>
                  </Option>
                ))}
              </>
            )}
            <div>
              <input
                ref={inputFocusRef}
                type={isFocuses ? 'url' : 'text'}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onChange={handleChange}
                autoComplete="off"
                value={valueSelected}
                readOnly={readOnly}
                {...rest}
                className="ps-2"
              />
              <input
                type="hidden"
                name={name}
                defaultValue={defaultValue}
                ref={inputRef}
                value={multiSelect ? undefined : optionSelected}
              />
            </div>
          </div>
          <button
            type="button"
            className="bg-transparent border-0"
            onClick={handleClickArrow}
          >
            <IoIosArrowDown size={24} color="#808080" />
          </button>
        </div>
        {isFocuses && (
          <div className="position-absolute options">
            {values.map((option) => (
              <button
                key={option.id}
                type="button"
                onClick={() => handleClick(option)}
                className="w-100"
                disabled={option.notSelectable}
              >
                {option.value}
              </button>
            ))}
          </div>
        )}
      </Container>
      {error && <span className="small text-danger error">{error}</span>}
    </>
  );
};

export default Select;
