import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/Header';

// import api from '~/services/api';
// import { useAuth } from '~/hooks/Auth';

const DefaultLayout: React.FC = ({ children }) => {
  // const { signOut } = useAuth();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // useEffect(() => {
  //   api.get('check-login-admin').catch(() => {
  //     signOut();
  //   });
  // }, [signOut]);

  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default DefaultLayout;
