import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import InputImage from '~/components/InputImage';

interface IFormData {
  name: string;
  description: string;
}

const CategoriesCreate: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [icon, setIcon] = useState<File | undefined>(undefined);
  const [iconError, setIconError] = useState('');

  const handleChangeIcon = useCallback((file) => {
    setIconError('');
    setIcon(file);
  }, []);

  const handleRemoveIcon = useCallback(() => {
    setIcon(undefined);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          icon: Yup.string().when('$icon', {
            is: (iconCheck: boolean) => iconCheck,
            then: Yup.string().required('O icone é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            icon: !icon,
          },
        });

        const formData = new FormData();
        formData.append('file', icon as File);
        formData.append('name', data.name);
        await api.post('categories', formData);

        Toast.fire({
          icon: 'success',
          title: 'Categoria cadastrada!',
        });
        history.push(`${process.env.PUBLIC_URL}/categorias`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          if (errors.icon) {
            setIconError(errors.icon);
          }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [history, icon]
  );

  return (
    <Container className="container py-5">
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="row justify-content-center"
      >
        <div className="col-12 mb-4">
          <h1 className="mb-0">Adicionar categoria</h1>
        </div>
        <div className="col-lg-6">
          <div className="box h-100 d-flex flex-column justify-content-between p-lg-5">
            <label className="w-100">
              Nome <Input name="name" className="mt-3" />
            </label>
            <div className="w-100 box bg-dark-2 py-3 py-lg-5">
              <span className="h5 mb-3 d-block">Icone</span>
              <InputImage
                name="icon"
                className="mt-4"
                cropImage
                aspect={1}
                cropOptions={
                  !icon
                    ? {
                        unit: 'px',
                        width: 20.44 * 5,
                        height: 20.44 * 5,
                        x: 0,
                        y: 0,
                      }
                    : undefined
                }
                onChange={handleChangeIcon}
                onRemove={handleRemoveIcon}
                error={iconError}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold mt-5"
            >
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default CategoriesCreate;
