import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdImage } from 'react-icons/io';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import Compressor from 'compressorjs';
import { FileUploader } from 'react-drag-drop-files';

import { BsTrashFill } from 'react-icons/bs';
import {
  Container,
  PhotoContainer,
  UploadPhoto,
  CropContainer,
} from './styles';

interface IInputImage {
  name: string;
  placeholder?: string;
  className?: string;
  onChange?(value: File): void;
  onRemove?(): void;
  value?: string;
  cropImage?: boolean;
  aspect?: number;
  cropOptions?: Crop;
  error?: string;
}

const TO_RADIANS = Math.PI / 180;

const InputImage: React.FC<IInputImage> = ({
  name,
  placeholder,
  className,
  onChange,
  onRemove,
  value,
  cropImage,
  aspect,
  cropOptions,
  error,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [valuePreview, setValuePreview] = useState('');
  const [fileData, setFileData] = useState<File>({} as File);
  const [cropData, setCropData] = useState<Crop>({} as Crop);
  const [crossOrigin, setCrossOrigin] = useState<'anonymous' | undefined>(
    'anonymous'
  );

  useEffect(() => {
    if (value) {
      setValuePreview(value);
    }
  }, [value]);

  useEffect(() => {
    if (cropOptions) {
      setCropData(cropOptions);
    }
  }, [cropOptions]);

  const handleChange = useCallback(
    (file: File) => {
      if (file) {
        if (file.type !== 'image/svg+xml') {
          new Compressor(file, {
            quality: 0.75,
            maxWidth: 1920,
            maxHeight: 1920,
            success: (compressedResult) => {
              setFileData(compressedResult as File);
              setValuePreview(URL.createObjectURL(compressedResult));
              if (onChange) {
                onChange(file);
              }
            },
          });
        } else if (onChange) {
          setValuePreview(URL.createObjectURL(file));
          onChange(file);
        }
      } else {
        setValuePreview('');
      }
    },
    [onChange]
  );

  const blobToFile = useCallback((theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  }, []);

  const toBlob = useCallback((canvas: HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve: any) => {
      canvas.toBlob(resolve);
    });
  }, []);

  const canvasPreview = useCallback(
    (
      image: HTMLImageElement,
      canvas: HTMLCanvasElement,
      crop: PixelCrop,
      scale = 1,
      rotate = 0
    ) => {
      const newCanvas = canvas;
      const ctx = newCanvas.getContext('2d');

      if (!ctx) {
        throw new Error('No 2d context');
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio;

      newCanvas.width = Math.floor(crop.width * scaleX * pixelRatio);
      newCanvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = 'high';

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const rotateRads = rotate * TO_RADIANS;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      ctx.translate(-cropX, -cropY);
      ctx.translate(centerX, centerY);
      ctx.rotate(rotateRads);
      ctx.scale(scale, scale);
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      ctx.restore();
    },
    []
  );

  const handleCompleteCrop = useCallback(
    async (e) => {
      if (e.width && e.height && imgRef.current) {
        const canvas = document.createElement('canvas');
        canvasPreview(imgRef.current, canvas, e, 1, 0);
        const blob = await toBlob(canvas);
        let fileName = name;
        if (!fileData.name) {
          const [, type] = blob.type.split('/');
          fileName = `${fileName}.${type}`;
        }
        const file = blobToFile(blob, fileData.name || fileName);
        if (onChange) {
          onChange(file);
        }
      }
    },
    [blobToFile, canvasPreview, fileData.name, name, onChange, toBlob]
  );

  const handleClickRemoveFile = useCallback(() => {
    setValuePreview('');
    setFileData({} as File);
    if (onRemove) {
      onRemove();
    }
  }, [onRemove]);

  const handleImageError = useCallback(() => {
    if (!crossOrigin) {
      setCrossOrigin('anonymous');
    } else if (crossOrigin === 'anonymous') {
      setCrossOrigin(undefined);
    }
  }, [crossOrigin]);

  return (
    <Container>
      <div className={valuePreview && cropImage ? 'pb-5' : 'pb-0'}>
        {(!valuePreview || !cropImage) && (
          <PhotoContainer src={valuePreview || ''} className={className}>
            {!valuePreview && (
              <>
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={['JPG', 'PNG', 'SVG']}
                >
                  <UploadPhoto>
                    <div className="camera">
                      <IoMdImage size={50} color="#BBBBBB" />
                      {placeholder !== '' && (
                        <span className="fw-medium text-secondary mt-3">
                          {typeof placeholder === 'string' ? (
                            placeholder
                          ) : (
                            <>
                              Carregue seu arquivo aqui ou <br />
                              <u className="text-primary">procure o arquivo</u>
                            </>
                          )}
                        </span>
                      )}
                    </div>
                  </UploadPhoto>
                </FileUploader>
              </>
            )}
          </PhotoContainer>
        )}
        {valuePreview && cropImage && (
          <CropContainer>
            <ReactCrop
              crop={cropData}
              onChange={(c) => setCropData(c)}
              onComplete={handleCompleteCrop}
              aspect={aspect}
              className="position-relative"
            >
              {/* {console.log(crossOrigin)} */}
              <img
                ref={imgRef}
                src={valuePreview}
                alt="file"
                className="w-100 h-100"
                crossOrigin={crossOrigin}
                id={name}
                onError={handleImageError}
              />
            </ReactCrop>
            <button
              type="button"
              onClick={handleClickRemoveFile}
              className="border-0 bg-transparent btn-remove-photo"
            >
              Remover foto
              <BsTrashFill size={20} color="#FC5D4A" className="ms-2" />
            </button>
          </CropContainer>
        )}
      </div>
      {error && <span className="small text-error error">{error}</span>}
    </Container>
  );
};

export default InputImage;
