import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface ContainerProps {
  padding?: string;
  height?: string;
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1.5px solid #e6e6e6;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: ${({ height }) => height || 'calc(100% - 32px)'};
  transition-duration: 0.3s;

  + div {
    margin-top: 8px;
  }

  textarea {
    padding: ${({ padding }) => padding || '5px'};
    flex: 1;
    background: transparent;
    border: 0;
    color: #707070;
    transition-duration: 0.2s;
    height: 100%;
    resize: none;
    outline: 0;

    ::placeholder {
      color: #707070;
    }
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030 !important;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      svg {
        color: #18191a;
      }
    `}

  ${(props) =>
    props.isFocuses &&
    css`
      color: ${darken(0.1, '#e6e6e6')};
      border-color: ${darken(0.1, '#e6e6e6')};
    `}
`;
