import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  padding: 20px 0;

  @media screen and (min-width: 576px) {
    padding: 20px;
  }
`;
