import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface ContainerProps {
  height?: string;
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.label<ContainerProps>`
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background-color: #f9f9f9;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: ${({ height }) => height || '44px'};
  transition-duration: 0.3s;

  + div {
    margin-top: 8px;
  }

  > div {
    width: 100%;
    position: relative;

    .inputs {
      width: 100%;
    }
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #707070;
    transition-duration: 0.2s;

    ::placeholder {
      color: #707070;
    }

    :focus {
      border: none;
      outline: 0;
    }

    :disabled {
      opacity: 0.7;
    }
  }

  .options {
    border-radius: 0 0 10px 10px;
    border: 1px solid #e6e6e6;
    background-color: #f9f9f9;
    width: calc(100% + 2px);
    left: -1px;
    top: 100%;
    max-height: 200px;
    overflow: auto;
    z-index: 100;

    button {
      border: none;
      border-bottom: 1px solid #e6e6e6;
      background: transparent;
      transition-duration: 0.3s;
      color: #707070;
      min-height: 52px;

      :hover {
        background-color: ${darken(0.03, '#f9f9f9')};
      }
    }
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #ff333d;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: ${darken(0.1, '#e6e6e6')};
      border-color: ${darken(0.1, '#e6e6e6')};
      border-radius: 10px 10px 0 0 !important;
    `}
`;

export const Option = styled.div`
  span {
    color: #fff;
  }
`;
