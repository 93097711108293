import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}

interface IOptions {
  active: boolean;
}

export const Container = styled.div`
  .table-orders {
    > div {
      overflow: hidden;

      :before {
        height: 15px;
      }
      :after {
        height: 15px;
      }

      .content {
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 0;
      }

      .tb-row {
        + .tb-row {
          border-top: 0.5px solid #e2e2e2;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .table-orders {
      > div {
        overflow: unset;

        :before {
          height: 50px;
        }

        :after {
          height: 27px;
        }

        .tb-row {
          + .tb-row {
            border-top: none;
          }
        }

        .content {
          padding-right: 0;
          padding-left: 0;
          padding-bottom: 17px;
          overflow: unset;

          .tb-header,
          .tb-body {
            width: 100%;
          }

          .tb-body {
            .tb-row:hover {
              transition-duration: 0.3s;
            }
          }

          .tb-header .tb-row,
          .tb-body .tb-row {
            width: 98%;
            display: flex;
            justify-content: space-between;

            > button:nth-child(1),
            > div:nth-child(1) {
              width: 100px;
            }

            > button:nth-child(2),
            > div:nth-child(2) {
              width: calc(100% - 450px);
            }

            > button:nth-child(3),
            > div:nth-child(3) {
              width: 100px;
            }

            > button:nth-child(4),
            > div:nth-child(4) {
              width: 200px;
            }

            > button:nth-child(5),
            > div:nth-child(5) {
              width: 50px;
            }
          }
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 38px;
  height: 38px;
  border-radius: 50%;
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    border-radius: 10px;
    padding: 1rem;

    h5 {
      font-weight: 600;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;
      background: none;

      .sr-only {
        display: none;
      }
    }

    .btn-outline-primary {
      height: 45px;
      margin-top: 1.5rem;

      svg {
        transition-duration: 0.3s;
        fill: #e50914;
      }

      :hover {
        svg {
          fill: #fff;
        }
      }
    }

    .error {
      color: #ff333d;
    }
  }

  @media screen and (min-width: 576px) {
    padding: 1.5rem;
  }
`;

export const Options = styled.div<IOptions>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  background: #fff;
  border: 1px solid #e2e2e2;
  width: 120px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -70px;
  left: 100px;
  transform: translateX(-50%);

  a,
  button {
    background: transparent;
    border: none;
    text-align: center;
    padding: 8px 0;

    + button {
      border-top: 1px solid #e2e2e2;
    }
  }

  .btn-edit {
    color: #707070;
  }

  .btn-delete {
    color: #ff333d;
  }

  .btn-print {
    color: #918d8d;
  }

  :after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    border-right: 2px solid #e2e2e2;
    border-bottom: 2px solid #e2e2e2;
    position: absolute;
    bottom: 8px;
    left: -11%;
    transform: rotateZ(135deg) translateX(-50%);
  }

  @media screen and (min-width: 992px) {
    top: -110px;
    left: 10px;

    :after {
      bottom: -14px;
      left: 50%;
      transform: rotateZ(45deg) translateX(-50%);
    }
  }
`;
