import styled from 'styled-components';

interface IAvatar {
  src: string;
}

interface IProfileOptions {
  open: boolean;
}

interface INotifications {
  opened: boolean;
}

interface IMenuButton {
  opened: boolean;
}

interface IMenu {
  opened: boolean;
}

export const Container = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 101;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
  background: #fff;

  .logo {
    img {
      width: 150px;
    }
  }

  @media screen and (min-width: 992px) {
    .body-content {
      width: calc(100% - 100px);
    }
  }
`;

export const MenuButton = styled.button<IMenuButton>`
  width: 30px;
  height: 30px;
  border: none;
  background: transparent;

  span {
    width: 25px;
    height: 2px;
    border-radius: 25px;
    background-color: #202020;
    display: block;
    transition-duration: 0.3s;

    :nth-child(1) {
      transform: rotateZ(${(props) => (props.opened ? '45deg' : '0deg')});
    }

    :nth-child(2) {
      opacity: ${(props) => (props.opened ? 0 : 1)};
      margin: ${(props) => (props.opened ? '-2px' : '3px')} 0;
    }

    :nth-child(3) {
      transform: rotateZ(${(props) => (props.opened ? '-45deg' : '0deg')});
    }
  }
`;

export const Menu = styled.div<IMenu>`
  transition-duration: 0.3s;
  position: absolute;
  width: 100%;
  height: calc(100vh - 58px);
  top: 61px;
  left: ${(props) => (props.opened ? 0 : '-1000px')};
  z-index: 100;
  background: #fff;

  @media screen and (min-width: 992px) {
    position: relative;
    width: unset;
    height: unset;
    top: unset;
    left: unset;
  }
`;

export const MenuItem = styled.div`
  > a {
    transition-duration: 0.3s;
    color: #858585;
    position: relative;

    :after {
      content: '';
      transition-duration: 0.3s;
      width: calc(100% + 24px);
      height: 2px;
      background: #e50914;
      border-radius: 40px;
      position: absolute;
      bottom: -8px;
      left: -12px;
      opacity: 0;
    }

    :hover {
      color: #202020;

      :after {
        opacity: 1;
      }
    }
  }

  a.active {
    color: #202020;

    :after {
      opacity: 1;
    }
  }

  a.way {
    color: #202020;

    :after {
      opacity: 0;
    }
  }

  + div {
    margin-top: 24px;
  }

  @media screen and (min-width: 992px) {
    + div {
      margin-top: 0;
      margin-left: 30px;
    }
  }
`;

export const Profile = styled.div`
  position: relative;

  @media screen and (min-width: 992px) {
    :after {
      content: '';
      width: calc(160% + 30px);
      height: 61px;
      position: absolute;
      background: #242526;
      left: -15px;
      top: -15px;
      z-index: -1;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-color: #696969;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const ProfileOptions = styled.div<IProfileOptions>`
  transition-duration: 0.3s;
  width: 160%;

  a {
    color: #bbbbbb;

    svg {
      display: none;
    }

    :after {
      content: '';
      transition-duration: 0.3s;
      width: calc(100% + 24px);
      height: 2px;
      background: #e50914;
      border-radius: 40px;
      position: absolute;
      bottom: -8px;
      left: -12px;
      opacity: 0;
    }

    :hover {
      color: #ffffff;

      :after {
        opacity: 1;
      }
    }

    + a,
    + button {
      margin-top: 24px;
    }
  }

  button {
    transition-duration: 0.3s;
  }

  @media screen and (min-width: 992px) {
    border-radius: 0px 0px 20px 20px;
    position: absolute;
    top: ${(props) => (props.open ? 'calc(100% + 14px)' : '-200px')};
    z-index: -1;
    left: -15px;

    a {
      svg {
        display: unset;
        transition-duration: 0.3s;
      }

      :hover {
        color: #ffffff;

        small,
        svg {
          color: #ffffff !important;
        }
      }

      :after {
        display: none;
      }

      + a,
      + button {
        margin-top: 0;
        border-top: 1px solid #3a3a3a !important;
      }
    }

    a.active {
      color: #ffffff;

      svg {
        color: #ffffff !important;
      }
    }
  }
`;

export const Notifications = styled.div<INotifications>`
  transition-duration: 0.3s;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  opacity: ${(props) => (props.opened ? 1 : 0)};
  visibility: ${(props) => (props.opened ? 'visible' : 'hidden')};
  z-index: 1000;

  > button {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    cursor: default !important;
  }

  > div {
    transition-duration: 0.3s;
    position: relative;
    right: ${(props) => (props.opened ? 0 : '-1000px')};
    width: 320px;
    height: 100%;
    overflow: auto;
  }
`;

export const Notification = styled.div`
  background: #303030;
  border: 1px solid #3a3a3a;
  border-radius: 10px;

  + div {
    margin-top: 20px;
  }
`;

export const Spacer = styled.div`
  width: 100%;
  height: 61px;
`;
