import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 77px;
  padding: 5px 25px;

  input {
    width: calc(100% - 33px);
    border: none;
    background: transparent;
  }
`;
