import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputImage from '~/components/InputImage';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';

interface ICategory {
  id: number;
  name: number;
}

interface IProductResponse {
  id: number;
  category_id: number;
  title: string;
  description: string;
  value: string;
  image_url: string;
}

interface IFormData {
  category_id: string;
  title: string;
  description: string;
  price: string;
}

interface IParams {
  slug: string;
}

const ProductsCreate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [photo, setPhoto] = useState<File | undefined>(undefined);
  const [photoUrl, setPhotoUrl] = useState('');
  const [photoError, setPhotoError] = useState('');
  const [product, setProduct] = useState({} as IFormData);
  const [productId, setProductId] = useState(0);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [categorySelected, setCategorySelected] = useState({} as IOption);

  useEffect(() => {
    api
      .get<IProductResponse>(`products/${params.slug}`)
      .then(async (response) => {
        const data: IFormData = {
          category_id: response.data.category_id.toString(),
          title: response.data.title,
          description: response.data.description,
          price: response.data.value,
        };

        const responseCategories = await api.get('categories');

        const categoriesOption: IOption[] = responseCategories.data.data.map(
          (category: ICategory) => ({
            id: category.id,
            value: category.name,
            selected: category.id === response.data.category_id,
            notSelectable: false,
          })
        );

        categoriesOption.unshift({
          id: undefined,
          value: 'Selecione',
          selected: false,
          notSelectable: true,
        });

        const category = categoriesOption.find(
          (categoryData) => categoryData.selected
        );

        if (category) {
          setCategorySelected(category);
        }

        setCategories(categoriesOption);
        setProduct(data);
        setProductId(response.data.id);
        setPhotoUrl(response.data.image_url);
      });
  }, [params.slug]);

  useEffect(() => {
    setCategories((state) => {
      const newCategories = state.map((category) => {
        if (category.id === categorySelected.id) {
          return {
            ...category,
            selected: true,
          };
        }
        return {
          ...category,
          selected: false,
        };
      });

      return newCategories;
    });
  }, [categorySelected.id]);

  const handleChangePhoto = useCallback((file) => {
    setPhotoError('');
    setPhoto(file);
  }, []);

  const handleRemovePhoto = useCallback(() => {
    setPhoto(undefined);
    setPhotoUrl('');
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          category: Yup.string().when('$category', {
            is: (categoryCheck: boolean) => categoryCheck,
            then: Yup.string().required('A categoria é obrigatória'),
            otherwise: Yup.string(),
          }),
          photo: Yup.string().when('$photo', {
            is: (photoCheck: boolean) => photoCheck,
            then: Yup.string().required('A foto é obrigatória'),
            otherwise: Yup.string(),
          }),
          title: Yup.string().required('O nome é obrigatório'),
          description: Yup.string().required('A descrição é obrigatória'),
          price: Yup.string().required('O preço é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            category: Object.keys(categorySelected).length === 0,
            photo: !photoUrl && !photo,
          },
        });

        const formData = new FormData();

        let { price } = data;

        if (Number.isNaN(parseFloat(data.price))) {
          price = data.price
            .replace('R$', '')
            .replaceAll('.', '')
            .replace(',', '.');
        }

        formData.append('category_id', categorySelected.id as string);
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('value', price);

        if (photo) {
          formData.append('file', photo);
        }

        await api.post(`products/${productId}`, formData);

        Toast.fire({
          icon: 'success',
          title: 'Produto editado!',
        });
        history.push(`${process.env.PUBLIC_URL}/produtos`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);

          if (errors.photo) {
            setPhotoError(errors.photo);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        }
      }
    },
    [categorySelected, history, photo, photoUrl, productId]
  );

  const handleSelectCategory = useCallback((data) => {
    setCategorySelected(data);
  }, []);

  return (
    <>
      <Container className="container py-5">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="mb-0">Atualizar produto</h1>
          </div>
          <div className="col-12">
            <Form
              ref={formRef}
              initialData={product}
              onSubmit={handleSubmit}
              className="row box bg-dark-2 p-lg-5"
            >
              <div className="col-lg-4 mb-4 mb-lg-0">
                <div className="w-100">
                  <span className="mb-3 d-block">Foto</span>
                  <InputImage
                    name="photo"
                    placeholder=""
                    className="bg-dark-3 mt-3"
                    onChange={handleChangePhoto}
                    cropImage
                    aspect={20.44 / 12.63}
                    cropOptions={
                      !photo
                        ? {
                            unit: 'px',
                            width: 20.44 * 5,
                            height: 12.63 * 5,
                            x: 0,
                            y: 0,
                          }
                        : undefined
                    }
                    value={photoUrl}
                    error={photoError}
                    onRemove={handleRemovePhoto}
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="h-100 row flex-column justify-content-between">
                  <div className="col-12">
                    <label className="w-100">
                      Nome <Input name="title" className="mt-3 input" />
                    </label>
                  </div>
                  <div className="col-12">
                    <label className="w-100 mt-4">
                      Descrição{' '}
                      <Textarea name="description" className="mt-3" rows={5} />
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-4">
                <label className="w-100">
                  Valor{' '}
                  <InputMask kind="money" name="price" className="mt-3 input" />
                </label>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="w-100">
                  <label className="w-100">Categorias</label>
                  <Select
                    name="categories"
                    options={categories}
                    className="mt-3 input-select"
                    onChange={handleSelectCategory}
                  />
                </div>
              </div>
              <div className="col-lg-4 mt-4 d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary py-2 fw-bold mt-5 px-5 ms-auto d-block"
                >
                  Salvar
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ProductsCreate;
