import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '~/pages/Login';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';

import Dashboard from '~/pages/Dashboard';
import Categories from '~/pages/Categories';
import CategoryCreate from '~/pages/Categories/Create';
import CategoryUpdate from '~/pages/Categories/Update';
import Products from '~/pages/Products';
import ProductsCreate from '~/pages/Products/Create';
import ProductsUpdate from '~/pages/Products/Update';
import Orders from '~/pages/Orders';
import Employees from '~/pages/Employees';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-a-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resetar-senha/:token`}
        exact
        component={ResetPassword}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        isPrivate
        component={Dashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias`}
        isPrivate
        exact
        component={Categories}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/cadastrar`}
        isPrivate
        exact
        component={CategoryCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categorias/:slug`}
        isPrivate
        exact
        component={CategoryUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/produtos`}
        exact
        isPrivate
        component={Products}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/produtos/cadastrar`}
        isPrivate
        component={ProductsCreate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/produtos/:slug`}
        exact
        isPrivate
        component={ProductsUpdate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/pedidos`}
        exact
        isPrivate
        component={Orders}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/funcionarios`}
        exact
        isPrivate
        component={Employees}
      />
    </Switch>
  );
};

export default routes;
