import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background-color: #f9f9f9;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  transition-duration: 0.3s;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;

    .button-show {
      width: 30px;
      height: 30px;
      background: none;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #707070;
    transition-duration: 0.2s;

    ::placeholder {
      color: #707070;
    }

    :focus {
      border: none;
      outline: 0;
    }
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #ff333d;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: ${darken(0.1, '#e6e6e6')};
      border-color: ${darken(0.1, '#e6e6e6')};
    `}
`;
