import styled from 'styled-components';
import List from 'react-chatview';

export const Container = styled(List)`
  height: calc(100vh - 61px);
`;

export const Category = styled.div`
  position: relative;

  img {
    width: 50px;
    height: 50px;
  }

  div {
    border: 1px solid #e6e6e6;
    box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  button {
    top: 15px;
    right: 30px;
  }

  .btn-edit {
    bottom: 15px;
    right: 30px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
  }
`;
