import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import Swal from 'sweetalert2';

import { RiEditFill } from 'react-icons/ri';
import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Product } from './styles';
import Search from '~/components/Search';

interface IProduct {
  id: number;
  title: string;
  description: string;
  image_url: string;
}

interface IProductData {
  current_page: number;
  last_page: number;
  data: IProduct[];
}

const Products: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const loadProducts = useCallback(async (pageData: number, search = '') => {
    const response = await api.get<IProductData>(`products`, {
      params: {
        all: true,
        page: pageData,
        search,
      },
    });

    if (pageData === 1) {
      setProducts(response.data.data);
    } else {
      setProducts((state) => [...state, ...response.data.data]);
    }
    setLastPage(response.data.last_page);
  }, []);

  useEffect(() => {
    loadProducts(1);
  }, [loadProducts]);

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        loadProducts(page + 1);
        setPage(page + 1);
      }
    } catch (error) {
      Swal.fire(
        'Opss...',
        'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
        'error'
      );
    }
  }, [lastPage, loadProducts, page]);

  const handleClickDelete = useCallback(
    (product_id: number) => {
      Swal.fire({
        title: 'Deletar esse produto?',
        html: '<small>Deseja realmente deletar este produto? Isto é irreversivel.</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`products/${product_id}`);

            const newProducts = products.filter(
              (product) => product.id !== product_id
            );
            setProducts(newProducts);

            Toast.fire({
              icon: 'success',
              title: 'Categoria deletada!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [products]
  );

  const handleSearch = useCallback(
    (value) => {
      loadProducts(1, value);
    },
    [loadProducts]
  );

  return (
    <Container scrollLoadThreshold={100} onInfiniteLoad={handleLoad}>
      <div className="container pt-5">
        <div className="row pt-4 pt-lg-5 pb-5">
          <div className="col-12 mb-4 mb-lg-5 pb-4 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
            <h1 className="fw-semibold mb-md-4 mb-lg-0 ms-lg-4">Produtos</h1>
            <div className="d-flex flex-column flex-md-row align-items-center">
              <Search
                onSearch={handleSearch}
                className="search me-md-3 mt-3 mb-4 my-md-0 w-100 w-md-unset"
              />
              <Link
                to={`${process.env.PUBLIC_URL}/produtos/cadastrar`}
                className="d-flex justify-content-center align-items-center border-0 btn btn-primary px-5 py-3 w-100 w-md-unset"
              >
                <BsPlus size={24} color="#fff" />
                <span className="text-gray fw-bold ms-2">Produto</span>
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {products.map((product) => (
                <div key={product.id} className="col-md-6 col-lg-4 mb-4">
                  <Product
                    src={product.image_url}
                    className="p-4 d-flex flex-column justify-content-between"
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={() => handleClickDelete(product.id)}
                      >
                        <MdDelete size={24} color="#FF333D" />
                      </button>
                      <Link
                        to={`${process.env.PUBLIC_URL}/produtos/${product.id}`}
                        className="btn-edit bg-white border-0 d-flex align-items-center justify-content-center"
                      >
                        <RiEditFill size={20} color="#3A3A3A" />
                      </Link>
                    </div>
                    <p className="d-flex justify-content-between align-items-end text-white py-4 w-100 mb-0">
                      <span>
                        <span className="text-gray d-block mb-2 fw-bold">
                          {product.title}
                        </span>
                        <span className="text-gray d-block">
                          {product.description}
                        </span>
                      </span>
                    </p>
                  </Product>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Products;
