import styled from 'styled-components';

import banner from '~/assets/banners/background-login.png';

export const Container = styled.div`
  > div {
    min-height: 100vh;
  }
`;

export const Content = styled.div`
  border-radius: 0px 33px 33px 0px;
  background: #fff;

  form {
    width: 100%;

    button.btn-primary {
      border-radius: 10px !important;
      font-size: 18px;
      height: 54px;
    }
  }

  a.btn-primary {
    border-radius: 10px !important;
    font-size: 18px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    form {
      width: 75%;
    }
  }

  @media screen and (min-width: 1600px) {
    form {
      width: 80%;
    }
  }
`;

export const Background = styled.div`
  background-image: url(${banner});
  background-size: cover;
  background-position: left center;
  position: relative;

  .logo {
    width: 105px;
  }

  @media (min-width: 992px) {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    flex: 0 0 auto;
    width: 61%;
    z-index: -1;
  }
`;
