import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import {
  BsFileEarmarkPdfFill,
  BsFillTrashFill,
  BsPlus,
  BsThreeDotsVertical,
} from 'react-icons/bs';

import { RiEdit2Fill } from 'react-icons/ri';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal, Options } from './styles';
import Table, { IColumn } from '~/components/Table';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Loading from '~/components/Loading';
import { formatPrice } from '~/utils/format';
import Select, { IOption } from '~/components/Select';
import Toast from '~/utils/toast';
import InputDate from '~/components/InputDate';
import generateUrlBlob from '~/utils/generateUrlBlob';

interface IProductResponse {
  id: number;
  title: string;
  value: string;
}

interface IProduct {
  id: number;
  title: string;
  value: string;
}

interface IOrderProductResponse {
  id: number;
  product_id: number;
  quantity: number;
  unit_price: string;
  total_price: string;
}

interface IOrderProduct {
  id?: number;
  product_id: number;
  quantity: number;
  unit_price: string;
  total_price: string;
  new: boolean;
}

interface IOrderResponse {
  id: string;
  name: string;
  total: number;
  date: string;
  orders_products: IOrderProductResponse[];
}

interface IOrderData {
  data: IOrderResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IOrder {
  id: string;
  name: string;
  total: string;
  date: Date;
  orders_products: IOrderProductResponse[];
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IFormData {
  name: string;
  total: string;
  date: string;
}

const Orders: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const updateFormRef = useRef<FormHandles>(null);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('Carregando...');
  const [page, setPage] = useState(1);
  const [orderSelected, setOrderSelected] = useState({} as IOrder);
  const [orderDataSelected, setOrderDataSelected] = useState({} as IOrder);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [optionProducts, setOptionProducts] = useState<IOption[]>([]);
  const [orderProducts, setOrderProducts] = useState<IOrderProduct[]>([
    {
      product_id: 0,
      quantity: 0,
      unit_price: '',
      total_price: '',
      new: true,
    },
  ]);
  const [deletedOrderProducts, setDeletedOrderProducts] = useState<
    IOrderProduct[]
  >([]);
  const [nameError, setNameError] = useState('');
  const [productsError, setProductsError] = useState('');

  const loadOrders = useCallback(async (pageData, search = '') => {
    const response = await api.get<IOrderData>('orders', {
      params: { page: pageData, search },
    });
    const data: IOrder[] = response.data.data.map((order) => {
      const [firstName, secondName] = order.name.split(' ');
      return {
        id: order.id.toString().padStart(5, '0'),
        name: `${firstName} ${secondName}`,
        total: formatPrice(order.total),
        date: parseISO(order.date),
        orders_products: order.orders_products,
      };
    });

    setOrders(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    loadOrders(page)
      .then(async () => {
        const response = await api.get<IProductResponse[]>('products', {
          params: {
            noPagination: true,
          },
        });
        const data: IOption[] = response.data.map((product) => ({
          id: product.id,
          value: product.title,
          selected: false,
          notSelectable: false,
        }));

        data.unshift({
          id: 0,
          value: 'Selecione',
          selected: true,
          notSelectable: true,
        });

        setProducts(response.data);
        setOptionProducts(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadOrders, page]);

  const total = useMemo(() => {
    const totalData = orderProducts.reduce((accumulator, current) => {
      return accumulator + parseFloat(current.total_price || '0');
    }, 0);

    return totalData.toFixed(2);
  }, [orderProducts]);

  const handleClickDots = useCallback((order) => {
    setOrderDataSelected((state) =>
      order.id === state.id ? ({} as IOrder) : order
    );
  }, []);

  const handleClickUpdateOrder = useCallback((order: IOrder) => {
    const orderProductsData = order.orders_products.map<IOrderProduct>(
      (orderProduct) => ({
        id: orderProduct.id,
        product_id: orderProduct.product_id,
        quantity: orderProduct.quantity,
        unit_price: orderProduct.unit_price,
        total_price: orderProduct.total_price,
        new: false,
      })
    );
    setOrderProducts(orderProductsData);
    setShowUpdate(true);
  }, []);

  const handleClickDeleteOrder = useCallback(
    (order: IOrder) => {
      Swal.fire({
        title: 'Deseja deletar esse pedido?',
        html: '<small>Deseja realmente deletar este pedido? Isto é irreversivel.</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`orders/${order.id}`);

            const newOrders = orders.filter(
              (orderData) => orderData.id !== order.id
            );
            setOrders(newOrders);

            Toast.fire({
              icon: 'success',
              title: 'Pedido deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [orders]
  );

  const handleClickExportToPdf = useCallback((order: IOrder) => {
    setLoading(true);
    api
      .get(`orders/${order.id}/pdf`, {
        responseType: 'blob',
      })
      .then((response) => {
        setOrderSelected({} as IOrder);
        window.open(generateUrlBlob(response), '_blank', 'noopener,noreferrer');
      })
      .finally(() => setLoading(false));
  }, []);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Cliente',
        selector: 'name',
        cell: (row: IOrder) => (
          <div className="d-flex align-items-center">
            {/* <Avatar src={row.avatar} className="me-2" /> */}
            <p className="mb-0">{row.name}</p>
          </div>
        ),
      },
      {
        name: 'Total',
        selector: 'total',
      },
      {
        name: 'Data da compra',
        selector: 'date',
        cell: (row: IOrder) => (
          <div className="d-flex align-items-center">
            <p className="mb-0">{format(row.date, 'dd/MM/yyyy')}</p>
          </div>
        ),
      },
      {
        name: '',
        selector: 'id',
        cell: (row: IOrder) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options active={orderDataSelected.id === row.id}>
              <button
                type="button"
                onClick={() => handleClickUpdateOrder(row)}
                className="d-flex align-items-center btn-edit"
              >
                <RiEdit2Fill size={24} color="#707070" className="me-2" />{' '}
                Editar
              </button>
              <button
                type="button"
                onClick={() => handleClickDeleteOrder(row)}
                className="d-flex align-items-center btn-delete"
              >
                <BsFillTrashFill size={24} color="#FF333D" className="me-2" />{' '}
                Deletar
              </button>
              <button
                type="button"
                onClick={() => handleClickExportToPdf(row)}
                className="d-flex align-items-center btn-print"
              >
                <BsFileEarmarkPdfFill
                  size={24}
                  color="#918d8d"
                  className="me-2"
                />{' '}
                Imprimir
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [
      handleClickDeleteOrder,
      handleClickDots,
      handleClickUpdateOrder,
      handleClickExportToPdf,
      orderDataSelected.id,
    ]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setShowUpdate(false);
    setShowOrder(false);
    setOrderProducts([
      {
        id: undefined,
        product_id: 0,
        quantity: 0,
        unit_price: '',
        total_price: '',
        new: true,
      },
    ]);
    setDeletedOrderProducts([]);
    setOrderDataSelected({} as IOrder);
  }, []);

  const handleClickAddOrder = useCallback(async () => {
    setShow(true);
  }, []);

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleRowDoubleClick = useCallback((data: IOrder) => {
    setOrderSelected(data);
    setShowOrder(true);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadOrders(1, value);
    },
    [loadOrders]
  );

  const handleClickAddProduct = useCallback(() => {
    setOrderProducts((state) => [
      ...state,
      {
        product_id: 0,
        quantity: 0,
        unit_price: '',
        total_price: '',
        new: true,
      },
    ]);
  }, []);

  const handleSelectProduct = useCallback(
    (product, index) => {
      const newOrderProducts = orderProducts.slice();
      const productData = products.find(
        (dataProduct) => dataProduct.id === product.id
      );

      if (productData) {
        const orderProduct = newOrderProducts[index];
        orderProduct.product_id = productData.id;
        orderProduct.unit_price = productData.value;
        orderProduct.total_price = (
          parseFloat(productData.value) * orderProduct.quantity
        ).toFixed(2);
        newOrderProducts[index] = orderProduct;
      }

      setOrderProducts(newOrderProducts);
    },
    [orderProducts, products]
  );

  const handleChangeQuantityProduct = useCallback(
    (e, index) => {
      const newOrderProducts = orderProducts.slice();

      const orderProduct = newOrderProducts[index];
      orderProduct.quantity = e.target.value;
      orderProduct.total_price = (
        e.target.value * parseFloat(orderProduct.unit_price)
      ).toFixed(2);
      newOrderProducts[index] = orderProduct;

      setOrderProducts(newOrderProducts);
    },
    [orderProducts]
  );

  const handleClickDeleteProduct = useCallback(
    (index) => {
      if (!orderProducts[index].new) {
        setDeletedOrderProducts((state) => [...state, orderProducts[index]]);
      }

      const newOrderProducts = orderProducts.filter((_, idx) => idx !== index);
      if (newOrderProducts.length > 0) {
        setOrderProducts(newOrderProducts);
      } else {
        setOrderProducts([
          {
            id: undefined,
            product_id: 0,
            quantity: 0,
            unit_price: '',
            total_price: '',
            new: true,
          },
        ]);
      }
    },
    [orderProducts]
  );

  const handleChangeName = useCallback((e) => {
    const nameParts = e.target.value.split(' ');
    if (nameParts.length <= 1) {
      setNameError('Escreva o nome completo do cliente');
    } else {
      setNameError('');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        setProductsError('');
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome do cliente é obrigatório'),
          date: Yup.string().required('A data é obrigatória'),
          products: Yup.string().when('$products', {
            is: (productsCheck: boolean) => productsCheck,
            then: Yup.string().required(
              'Preencha todos os produtos, pois eles são obrigatórios'
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            products: orderProducts.find(
              (orderProduct) =>
                !orderProduct.product_id || !orderProduct.quantity
            ),
          },
        });

        if (!nameError) {
          setLoadingMessage('Criando pedido...');
          const [day, month, year] = data.date.split('/');
          const formData = {
            name: data.name,
            total: data.total,
            date: format(
              new Date(
                parseInt(year, 10),
                parseInt(month, 10) - 1,
                parseInt(day, 10)
              ),
              'yyyy-MM-dd hh:mm:ss'
            ),
          };
          const response = await api.post('orders', formData);

          if (orderProducts.length > 0) {
            setLoadingMessage('Linkando produtos com o pedido...');
            await new Promise<void>((resolve) => {
              orderProducts.forEach(async (orderProduct, index) => {
                const formDataOrderProducts = {
                  order_id: response.data.id,
                  product_id: orderProduct.product_id,
                  quantity: orderProduct.quantity,
                  unit_price: parseFloat(orderProduct.unit_price),
                  total_price: parseFloat(orderProduct.total_price),
                };
                await api.post('orders-products', formDataOrderProducts);

                if (orderProducts.length === index + 1) {
                  resolve();
                }
              });
            });
          }

          handleClose();
          loadOrders(page);
        }

        Toast.fire({
          icon: 'success',
          title: 'Pedido criado!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          if (errors.products) {
            setProductsError(errors.products);
          }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      } finally {
        setLoading(false);
        setLoadingMessage('Carregando...');
      }
    },
    [handleClose, loadOrders, nameError, orderProducts, page]
  );

  const handleSubmitUpdate = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        updateFormRef.current?.setErrors({});
        setProductsError('');
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome do cliente é obrigatório'),
          date: Yup.string().required('A data é obrigatória'),
          products: Yup.string().when('$products', {
            is: (productsCheck: boolean) => productsCheck,
            then: Yup.string().required(
              'Preencha todos os produtos, pois eles são obrigatórios'
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            products: orderProducts.find(
              (orderProduct) =>
                !orderProduct.product_id || !orderProduct.quantity
            ),
          },
        });

        if (!nameError) {
          setLoadingMessage('Criando pedido...');
          const [day, month, year] = data.date.split('/');
          const formData = {
            name: data.name,
            total: data.total,
            date: format(
              new Date(
                parseInt(year, 10),
                parseInt(month, 10) - 1,
                parseInt(day, 10)
              ),
              'yyyy-MM-dd hh:mm:ss'
            ),
          };
          const response = await api.put(
            `orders/${orderDataSelected.id}`,
            formData
          );

          if (deletedOrderProducts.length > 0) {
            setLoadingMessage('Deslinkando produtos removido do pedido...');
            await new Promise<void>((resolve) => {
              deletedOrderProducts.forEach(async (orderProduct, index) => {
                await api.delete(`orders-products/${orderProduct.id}`);

                if (deletedOrderProducts.length === index + 1) {
                  resolve();
                }
              });
            });
          }

          if (orderProducts.length > 0) {
            setLoadingMessage('Linkando produtos com o pedido...');
            await new Promise<void>((resolve) => {
              orderProducts.forEach(async (orderProduct, index) => {
                if (orderProduct.new) {
                  const formDataOrderProducts = {
                    order_id: response.data.id,
                    product_id: orderProduct.product_id,
                    quantity: orderProduct.quantity,
                    unit_price: parseFloat(orderProduct.unit_price),
                    total_price: parseFloat(orderProduct.total_price),
                  };
                  const responseOrderProduct = await api.post(
                    'orders-products',
                    formDataOrderProducts
                  );
                  orderProducts[index].id = responseOrderProduct.data.id;
                  orderProducts[index].new = false;
                } else {
                  const formDataOrderProducts = {
                    order_id: response.data.id,
                    product_id: orderProduct.product_id,
                    quantity: orderProduct.quantity,
                    unit_price: parseFloat(orderProduct.unit_price),
                    total_price: parseFloat(orderProduct.total_price),
                  };
                  const responseOrderProduct = await api.put(
                    `orders-products/${orderProduct.id}`,
                    formDataOrderProducts
                  );
                  orderProducts[index].quantity =
                    responseOrderProduct.data.quantity;
                  orderProducts[index].new = false;
                }

                if (orderProducts.length === index + 1) {
                  resolve();
                }
              });
            });
          }

          const newOrders = orders.slice();
          const orderIndex = newOrders.findIndex(
            (order) => order.id === orderDataSelected.id
          );

          if (orderIndex >= 0) {
            newOrders[orderIndex].name = data.name;
            newOrders[orderIndex].total = formatPrice(parseFloat(data.total));
            newOrders[orderIndex].date = new Date(
              parseInt(year, 10),
              parseInt(month, 10) - 1,
              parseInt(day, 10)
            );
            newOrders[orderIndex].orders_products =
              orderProducts as IOrderProductResponse[];
          }

          handleClose();
        }

        Toast.fire({
          icon: 'success',
          title: 'Pedido editado!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          updateFormRef.current?.setErrors(errors);

          if (errors.products) {
            setProductsError(errors.products);
          }
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      } finally {
        setLoading(false);
        setLoadingMessage('Carregando...');
      }
    },
    [
      deletedOrderProducts,
      handleClose,
      nameError,
      orderProducts,
      orderDataSelected.id,
      orders,
    ]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
              <h1 className="mb-0">Pedidos</h1>
              <div className="d-flex align-items-center mt-4">
                <button
                  type="button"
                  className="d-flex justify-content-center align-items-center border-0 btn btn-primary px-5 py-3 w-100 w-md-unset"
                  onClick={handleClickAddOrder}
                >
                  <BsPlus size={24} color="#fff" />
                  <span className="fw-bold ms-2">Pedido</span>
                </button>
              </div>
            </div>
            <Table
              columns={columns}
              data={orders}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-orders px-0"
              pagination
              onChangePage={handleChangePage}
              onRowDoubleClick={handleRowDoubleClick}
              searchable
              onSearch={handleSearch}
              exportToExcel
            />
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Novo pedido</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <label className="w-100">
                  Nome do cliente{' '}
                  <Input
                    name="name"
                    className="mt-3 input"
                    onChange={handleChangeName}
                    error={nameError}
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100">
                  Total{' '}
                  <InputMask
                    kind="money"
                    name="total"
                    className="mt-3 input"
                    disabled
                    value={total}
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <div className="w-100">
                  <label>Data</label>
                  <InputDate name="date" monthYearSelectable className="mt-3" />
                </div>
              </div>
              <div className="col-12">
                <h5 className="w-100">Produtos</h5>
                {orderProducts.map((orderProduct, index) => (
                  <div
                    key={index.toString()}
                    className={`row align-items-end mb-4 ${
                      index > 0 ? 'mt-n4' : ''
                    }`}
                  >
                    <div className="col-lg-5 mb-4">
                      <div className="w-100">
                        <label>Produto</label>{' '}
                        <Select
                          name={`product-${index}`}
                          options={optionProducts.map((optionProduct) =>
                            optionProduct.id === orderProduct.product_id
                              ? { ...optionProduct, selected: true }
                              : { ...optionProduct, selected: false }
                          )}
                          className="mt-3 input-select"
                          onChange={(option) =>
                            handleSelectProduct(option, index)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="w-100">
                        Quantidade{' '}
                        <Input
                          type="number"
                          name={`quantity-${index}`}
                          className="mt-3 input"
                          min={1}
                          value={orderProduct.quantity}
                          onChange={(e) =>
                            handleChangeQuantityProduct(e, index)
                          }
                        />
                      </label>
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="w-100">
                        Valor unitario{' '}
                        <InputMask
                          kind="money"
                          name={`unit_price-${index}`}
                          className="mt-3 input"
                          value={orderProduct.unit_price}
                          disabled
                        />
                      </label>
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="w-100">
                        Valor total{' '}
                        <InputMask
                          kind="money"
                          name={`total_price-${index}`}
                          className="mt-3 input"
                          value={orderProduct.total_price}
                          disabled
                        />
                      </label>
                    </div>
                    <div className="col-lg-1 d-flex align-items-center justify-content-center mb-4">
                      <button
                        type="button"
                        onClick={() => handleClickDeleteProduct(index)}
                        className="border-0 bg-transparent pb-2"
                      >
                        <BsFillTrashFill size={24} color="#FF333D" />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row mt-n4">
                  {productsError && (
                    <div className="col-12 mt-n4">
                      <small className="error">{productsError}</small>
                    </div>
                  )}
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100"
                      onClick={handleClickAddProduct}
                    >
                      <BsPlus size={24} color="#fff" />
                      <span className="fw-bold ms-2">Produto</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <button type="submit" className="btn btn-primary fw-bold px-4">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal size="xl" show={showUpdate} onHide={handleClose}>
        <Form
          ref={updateFormRef}
          initialData={orderDataSelected}
          onSubmit={handleSubmitUpdate}
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Editar pedido</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <label className="w-100">
                  Nome do cliente{' '}
                  <Input
                    name="name"
                    className="mt-3 input"
                    onChange={handleChangeName}
                    error={nameError}
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <label className="w-100">
                  Total{' '}
                  <InputMask
                    kind="money"
                    name="total"
                    className="mt-3 input"
                    disabled
                    value={total}
                  />
                </label>
              </div>
              <div className="col-lg-6 mb-4">
                <div className="w-100">
                  <label>Data</label>
                  <InputDate
                    name="date"
                    monthYearSelectable
                    className="mt-3"
                    value={orderDataSelected.date}
                  />
                </div>
              </div>
              <div className="col-12">
                <h5 className="w-100">Produtos</h5>
                {orderProducts.map((orderProduct, index) => (
                  <div
                    key={index.toString()}
                    className={`row align-items-end mb-4 ${
                      index > 0 ? 'mt-n4' : ''
                    }`}
                  >
                    <div className="col-lg-5 mb-4">
                      <div className="w-100">
                        <label>Produto</label>{' '}
                        <Select
                          name={`product-${index}`}
                          options={optionProducts.map((optionProduct) =>
                            optionProduct.id === orderProduct.product_id
                              ? { ...optionProduct, selected: true }
                              : { ...optionProduct, selected: false }
                          )}
                          className="mt-3 input-select"
                          onChange={(option) =>
                            handleSelectProduct(option, index)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="w-100">
                        Quantidade{' '}
                        <Input
                          type="number"
                          name={`quantity-${index}`}
                          className="mt-3 input"
                          min={1}
                          value={orderProduct.quantity}
                          onChange={(e) =>
                            handleChangeQuantityProduct(e, index)
                          }
                        />
                      </label>
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="w-100">
                        Valor unitario{' '}
                        <InputMask
                          kind="money"
                          name={`unit_price-${index}`}
                          className="mt-3 input"
                          value={orderProduct.unit_price}
                          disabled
                        />
                      </label>
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="w-100">
                        Valor total{' '}
                        <InputMask
                          kind="money"
                          name={`total_price-${index}`}
                          className="mt-3 input"
                          value={orderProduct.total_price}
                          disabled
                        />
                      </label>
                    </div>
                    <div className="col-lg-1 mb-4 d-flex align-items-center justify-content-center">
                      <button
                        type="button"
                        onClick={() => handleClickDeleteProduct(index)}
                        className="border-0 bg-transparent pb-2"
                      >
                        <BsFillTrashFill size={24} color="#FF333D" />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row mt-n4">
                  {productsError && (
                    <div className="col-12 mt-n4">
                      <small className="error">{productsError}</small>
                    </div>
                  )}
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-primary w-100"
                      onClick={handleClickAddProduct}
                    >
                      <BsPlus size={24} color="#fff" />
                      <span className="fw-bold ms-2">Produto</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <button type="submit" className="btn btn-primary fw-bold px-4">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {Object.keys(orderSelected).length > 0 && (
        <Modal size="xl" show={showOrder} onHide={handleClose}>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">
              Pedido N°: {orderSelected.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-4">
                <p>
                  <b>Nome do cliente:</b> {orderSelected.name}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b>Total:</b> {orderSelected.total}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b>Data:</b> {format(orderSelected.date, 'dd/MM/yyyy')}
                </p>
              </div>
              <div className="col-12 mt-5 mb-3">
                <h4>Produtos</h4>
              </div>
              {orderSelected.orders_products.map((orderProduct) => (
                <>
                  <div className="col-lg-4">
                    <p>
                      <b>Produto:</b>{' '}
                      {
                        products.find(
                          (product) => product.id === orderProduct.product_id
                        )?.title
                      }
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <p>
                      <b>Quantidade:</b> {orderProduct.quantity}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p>
                      <b>Preço unitario:</b>{' '}
                      {formatPrice(parseFloat(orderProduct.unit_price))}
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p>
                      <b>Valor Total:</b>{' '}
                      {formatPrice(parseFloat(orderProduct.total_price))}
                    </p>
                  </div>
                </>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end" />
        </Modal>
      )}
      <Loading show={loading} message={loadingMessage} />
    </Container>
  );
};

export default Orders;
