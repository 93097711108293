import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { format } from 'date-fns';
import { parseISO } from 'date-fns';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { BsFillTrashFill, BsPlus, BsThreeDotsVertical } from 'react-icons/bs';
import { Form } from '@unform/web';

import { RiEdit2Fill } from 'react-icons/ri';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Modal, Options } from './styles';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';
import Search from '~/components/Search';
import Input from '~/components/Input';

interface IUserResponse {
  id: string;
  name: string;
  email: string;
  created_at: string;
}

interface IUserData {
  data: IUserResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  joined_at: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IFormData {
  name: string;
  email: string;
  password: string;
}

const Employees: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const updateFormRef = useRef<FormHandles>(null);
  const [users, setUsers] = useState<IUser[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [userSelected, setUserSelected] = useState({} as IUser);
  const [nameError, setNameError] = useState('');

  const loadUsers = useCallback(async (pageData, search = '') => {
    const response = await api.get<IUserData>('users', {
      params: { page: pageData, search },
    });
    const data: IUser[] = response.data.data.map((user) => {
      const [firstName, secondName] = user.name.split(' ');
      return {
        id: user.id.toString().padStart(5, '0'),
        name: `${firstName} ${secondName}`,
        email: user.email,
        joined_at: format(parseISO(user.created_at), 'dd/MM/yyyy'),
      };
    });

    setUsers(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    loadUsers(page);
  }, [loadUsers, page]);

  const handleClickDots = useCallback((user) => {
    setUserSelected((state) => (user.id === state.id ? ({} as IUser) : user));
  }, []);

  const handleClickUpdateUser = useCallback(() => {
    setShowUpdate(true);
  }, []);

  const handleClickDeleteUser = useCallback(
    (user: IUser) => {
      Swal.fire({
        title: 'Deseja deletar esse funcionário?',
        html: '<small>Deseja realmente deletar este funcionário? Isto é irreversivel.</small>',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#e50914',
        cancelButtonColor: '#303030',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`users/${user.id}`);

            const newUsers = users.filter(
              (userData) => userData.id !== user.id
            );
            setUsers(newUsers);

            Toast.fire({
              icon: 'success',
              title: 'Funcionário deletado!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [users]
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Usuário',
        selector: 'name',
        cell: (row: IUser) => (
          <div className="d-flex align-items-center">
            <p className="mb-0">{row.name}</p>
          </div>
        ),
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Data de ingresso',
        selector: 'joined_at',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: IUser) => (
          <div className="position-relative">
            <button
              type="button"
              className="d-flex align-items-center justify-content-center border-0 bg-transparent"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options active={userSelected.id === row.id}>
              <button
                type="button"
                onClick={() => handleClickUpdateUser()}
                className="d-flex align-items-center btn-edit"
              >
                <RiEdit2Fill size={24} color="#707070" className="me-2" />{' '}
                Editar
              </button>
              <button
                type="button"
                onClick={() => handleClickDeleteUser(row)}
                className="d-flex align-items-center btn-delete"
              >
                <BsFillTrashFill size={24} color="#FF333D" className="me-2" />{' '}
                Deletar
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [
      handleClickDeleteUser,
      handleClickDots,
      handleClickUpdateUser,
      userSelected.id,
    ]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setShowUpdate(false);
    setUserSelected({} as IUser);
  }, []);

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleSearch = useCallback(
    (value) => {
      loadUsers(1, value);
    },
    [loadUsers]
  );

  const handleChangeName = useCallback((e) => {
    const nameParts = e.target.value.split(' ');
    if (nameParts.length <= 1) {
      setNameError('Escreva o nome completo, por favor');
    } else {
      setNameError('');
    }
  }, []);

  const handleClickAddEmployee = useCallback(() => {
    setShow(true);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!nameError) {
          const formData = {
            name: data.name,
            email: data.email,
            password: data.password,
          };

          await api.post('users', formData);

          handleClose();
          loadUsers(page);

          Toast.fire({
            icon: 'success',
            title: 'Funcionário criado!',
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [handleClose, loadUsers, nameError, page]
  );

  const handleSubmitUpdate = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        updateFormRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Informe um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!nameError) {
          const formData = {
            name: data.name,
            email: data.email,
            password: data.password,
          };

          await api.put(`users/${userSelected.id}`, formData);

          const newUsers = users.slice();
          const userIndex = newUsers.findIndex(
            (user) => user.id === userSelected.id
          );
          if (userIndex >= 0) {
            newUsers[userIndex].name = data.name;
            newUsers[userIndex].email = data.email;
          }

          setUsers(newUsers);
          handleClose();

          Toast.fire({
            icon: 'success',
            title: 'Funcionário editado!',
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          updateFormRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro ao logar, verifique seus dados.',
            'error'
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [handleClose, nameError, userSelected.id, users]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
              <h1 className="mb-0">Funcionários</h1>
              <div className="d-flex flex-column flex-md-row align-items-center">
                <Search
                  onSearch={handleSearch}
                  className="search me-md-3 mt-3 mb-4 my-md-0 w-100 w-md-unset"
                />
                <button
                  type="button"
                  className="d-flex justify-content-center align-items-center border-0 btn btn-primary px-5 py-3 w-100 w-md-unset"
                  onClick={handleClickAddEmployee}
                >
                  <BsPlus size={24} color="#fff" />
                  <span className="fw-bold ms-2">Funcionário</span>
                </button>
              </div>
            </div>
            <Table
              columns={columns}
              data={users}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-users px-0"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Novo funcionário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <label className="w-100">
                  Nome{' '}
                  <Input
                    name="name"
                    className="mt-3 input"
                    onChange={handleChangeName}
                    error={nameError}
                  />
                </label>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100">
                  E-mail{' '}
                  <Input type="email" name="email" className="mt-3 input" />
                </label>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100">
                  Senha{' '}
                  <Input
                    type="password"
                    name="password"
                    className="mt-3 input"
                  />
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <button type="submit" className="btn btn-primary fw-bold w-100">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showUpdate} onHide={handleClose}>
        <Form
          ref={updateFormRef}
          initialData={userSelected}
          onSubmit={handleSubmitUpdate}
        >
          <Modal.Header className="border-0" closeButton>
            <Modal.Title className="ml-auto">Atualizar funcionário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mb-4">
                <label className="w-100">
                  Nome{' '}
                  <Input
                    name="name"
                    className="mt-3 input"
                    onChange={handleChangeName}
                    error={nameError}
                  />
                </label>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100">
                  E-mail{' '}
                  <Input type="email" name="email" className="mt-3 input" />
                </label>
              </div>
              <div className="col-12 mb-4">
                <label className="w-100">
                  Senha{' '}
                  <Input
                    type="password"
                    name="password"
                    className="mt-3 input"
                  />
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-end">
            <button type="submit" className="btn btn-primary fw-bold w-100">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Loading show={loading} message="Carregando..." />
    </Container>
  );
};

export default Employees;
