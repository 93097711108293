import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  format,
  parseISO,
  lastDayOfMonth,
  subMonths,
  differenceInMonths,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Swal from 'sweetalert2';

import { Form } from '@unform/web';
import api from '~/services/api';

import { Container } from './styles';
import Graph, { ISerie } from '~/components/Graph';
import InputDate from '~/components/InputDate';

interface IOrder {
  id: string;
  name: string;
  total: string;
  date: string;
}

interface IPeriodDate {
  initalDate: Date;
  endDate: Date;
}

interface IMonth {
  id: number;
  name: string;
}

interface IPeriod {
  year: number;
  month: IMonth;
}

interface IFormData {
  initalDate: string;
  endDate: string;
}

const Dashboard: React.FC = () => {
  const [overviewSeries, setOverviewSeries] = useState<ISerie[]>([]);
  const [periodDate, setPeriodData] = useState<IPeriodDate>(() => {
    const currentDate = new Date();
    const lastDay = lastDayOfMonth(currentDate).getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    return {
      initalDate: subMonths(new Date(year, month, 1, 0, 0, 0, 0), 11),
      endDate: new Date(year, month, lastDay, 23, 59, 59, 999),
    };
  });
  const [errorDate, setErrorDate] = useState('');

  const period = useMemo<IPeriod[]>(() => {
    const selectedMonth = periodDate.endDate.getMonth();
    const selectedYear = periodDate.endDate.getFullYear();

    const data: IPeriod[] = [];
    for (let i = 11; i >= 0; i -= 1) {
      const monthCalc = selectedMonth - i;
      let year = selectedYear;
      if (monthCalc < 0) {
        year -= 1;
      }
      const date = new Date(year, monthCalc);
      const name = format(date, 'MMMM', {
        locale: ptBR,
      });
      const newMonth: IMonth = {
        id: date.getMonth(),
        name: `${name[0].toUpperCase()}${name.slice(1, 10)}`,
      };
      data.push({
        year: date.getFullYear(),
        month: newMonth,
      });
    }

    return data;
  }, [periodDate.endDate]);

  const loadOrders = useCallback(
    async (periodDateData: IPeriodDate, periodData: IPeriod[]) => {
      try {
        const response = await api.get<IOrder[]>('orders', {
          params: {
            initalDate: periodDateData.initalDate,
            endDate: periodDateData.endDate,
            noPagination: true,
          },
        });

        const dataTotalValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        periodData.forEach((dataPeriod, index) => {
          response.data.forEach((order) => {
            const orderDate = parseISO(order.date);
            if (orderDate.getMonth() === dataPeriod.month.id) {
              dataTotalValue[index] += parseFloat(order.total);
            }
          });
        });
        const totalValues: ISerie = {
          name: 'Valor total dos pedidos',
          data: dataTotalValue,
        };

        const seriesData: ISerie[] = [totalValues];

        setOverviewSeries(seriesData);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      }
    },
    []
  );

  useEffect(() => {
    loadOrders(periodDate, period);
  }, [loadOrders, period, periodDate]);

  const handleSubmitPeriod = useCallback((data: IFormData) => {
    setErrorDate('');
    const [initialDay, initialMonth, initialYear] = data.initalDate.split('/');
    const [endDay, endMonth, endYear] = data.endDate.split('/');

    const initialDate = new Date(
      parseInt(initialYear, 10),
      parseInt(initialMonth, 10) - 1,
      parseInt(initialDay, 10),
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      parseInt(endYear, 10),
      parseInt(endMonth, 10) - 1,
      parseInt(endDay, 10),
      23,
      59,
      59,
      999
    );

    if (differenceInMonths(endDate, initialDate) >= 12) {
      setErrorDate('Escolha um periodo de até 12 meses');
    } else {
      setPeriodData({
        initalDate: initialDate,
        endDate,
      });
    }
  }, []);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <h1 className="mb-0">Gráficos de pedidos</h1>
              <div>
                <Form
                  onSubmit={handleSubmitPeriod}
                  className="d-flex flex-column flex-md-row align-items-center mt-4 mt-lg-0"
                >
                  <div className="w-100 w-md-unset">
                    <InputDate
                      name="initalDate"
                      value={periodDate.initalDate}
                      monthYearSelectable
                    />
                  </div>
                  <div className="w-100 w-md-unset mx-3 my-3 my-md-0">
                    <InputDate
                      name="endDate"
                      value={periodDate.endDate}
                      monthYearSelectable
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-submit px-4 fw-bold w-100 w-lg-unset"
                  >
                    <span className="px-2">Filtrar</span>
                  </button>
                </Form>
                {errorDate && (
                  <span className="small text-error error">{errorDate}</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <Graph
              series={overviewSeries}
              categories={period.map((periodData) => periodData.month.name)}
              height={450}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
